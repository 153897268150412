/* @import url('https://fonts.googleapis.com/css2?family=M+PLUS+1:wght@100;300;400;500;600;700;800&display=swap'); */
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.4/font/bootstrap-icons.css");

/* font-family: 'Hellix'; */
body {
  font-family: "Hellix" !important;
}

.swal-title {
  color: rgba(0, 0, 0, 0.65) !important;
  font-weight: 500 !important;
}

.swal-text {
  color: #ffff !important;
}

.swal-modal {
  background-color: #fff !important;
}

.swal-icon--success__hide-corners,
.swal-icon--success:after,
.swal-icon--success:before {
  background-color: transparent;
}

.swal-button--danger {
  color: #ffffff;
  font: 14px nunito;
  background-color: #e64942;
  margin: 0px 16px 0px -20px;
  padding: 6px 30px;
}

.swal-button--danger:not([disabled]):hover {
  background-color: #df4740;
}

.swal-button--cancel {
  color: #555;
  font: 14px nunito;
  background: #efefef;
  margin: 0px 16px 0px 0px;
  padding: 7px 20px;
}

.swal-button--cancel:hover {
  background-color: #e8e8e8;
  color: white;
}

.swal-button--cancel:active {
  background-color: #d7d7d7;
}

.swal-button--cancel:not([disabled]):hover {
  background-color: #e8e8e8;
}

.swal-button--danger:active {
  background-color: #df4740;
}

.swal-button--danger:not([disabled]):hover {
  background-color: #df4740;
}

.swal-button--confirm {
  color: #ffffff;
  /* font: 14px nunito; */
  background-color: #df4740;
  margin: 0px 16px 0px -20px;
  padding: 6px 30px;
}

.swal-text {
  font-size: 20px;
  font-weight: 700;
  color: #e92b2b !important;
}

.MuiPaper-root {
  background-color: #ffffff !important;
}

.MuiIconButton-root {
  color: #86c1ed !important;
}

.MuiIconButton-root.Mui-disabled {
  color: #9a9cab !important;
}

.modal-title {
  color: #e70765;
}

.MuiIconButton-label .modal-title {
  color: #ffff;
}

.banner-button {
  background-image: linear-gradient(to left,
      #0db2de 0,
      #005bea 100%) !important;
  color: #fff !important;
}

.search-button {
  background-image: linear-gradient(to left,
      #0db2de 0,
      #005bea 100%) !important;
  color: #fff !important;
}

.navIcon i {
  font-size: 20px;
}

a {
  cursor: pointer;
}

/* for sidebar */

.sidebar-option {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  font-size: 13px;
  color: #506690;
  padding: 11px 14px;
  transition: 0.6s;
  margin-bottom: 4px;
  font-weight: 600;
  letter-spacing: 1px;
}

.sidebar-theme:before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.3;
  background-image: url("../img/sidebar_image.png");
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: cover;
}

.UserProfileDialogue {
  width: 600px;
  height: 750px;
  object-fit: cover;
}

.userProfileUserName {
  font-weight: 700;
  font-size: 36px;
  color: var(--bs-color);
}

.post_view_commentData {
  max-height: 650px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-top: 5px;
}

/* .text {
  padding-top: 5px;
  height: 35px;
  width: 35px;
  background-color: #ffff;
  border-radius: 50%;
  font-size: 16px;
  display: inline-block;
  border: 1px solid #e9e9e9;
  transition: all ease-in-out 0.5s;
}

.text:hover {
  background-color: #e4065d21;
  color: #000;
} */

/* for reelinfo */

@media only screen and (max-width: 800px) {
  #like {
    margin-top: 20px;
    margin-left: -10px;
  }

  #comment {
    margin-top: 20px;
  }
}

/* FOR USER INFO */

/*
    Infobox 3
*/
.infobox-3 {
  position: relative;
  border: 1px solid #191e3a;
  width: 100%;
  margin-top: 30px;
  padding: 50px 25px 25px 25px;
  border-radius: 6px;
  -webkit-box-shadow: 0px 2px 11px 0px rgba(6, 8, 24, 0.39);
  box-shadow: 0px 2px 11px 0px rgba(6, 8, 24, 0.39);
  margin-right: auto;
  margin-left: auto;
  background-color: #1f2940;
  background-repeat: no-repeat;
  background-size: cover;
}

.infobox-3 .info-icon {
  position: absolute;
  margin-bottom: 20px;
  /* background: #4361ee; */
  display: inline-block;
  top: -31px;
  padding: 6px;
  border-radius: 6px;
}

.infobox-3 .info-icon img {
  width: 80px;
  height: 80px;
  border-radius: 10px;
  stroke-width: 1px;
  color: #fff;
}

.infobox-3 .info-heading {
  font-weight: 600;
  font-size: 19px;
  margin-bottom: 5px;
  margin-top: 40px;
  letter-spacing: 1px;
}

.infobox-3 .info-text {
  font-size: 15px;
  color: #888ea8;
  margin-bottom: 10px;
}

.infobox-3 .info-link {
  color: #4361ee;
  font-weight: 600;
}

.infobox-3 .info-link svg {
  width: 15px;
  height: 15px;
}

a.info-text:hover {
  color: white;
}

.info-text-active {
  color: white;
}

/* error page 404 */

body.error404 {
  color: #888ea8;
  height: 100%;
  font-size: 0.875rem;
  background: #060818;
}

body.error404>.error-content {
  min-height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 30px;
}

.error404 .mini-text {
  font-size: 33px;
  font-weight: 700;
  margin-bottom: 0;
  color: #bfc9d4;
}

.error404 .img-cartoon {
  width: 170px;
  height: 170px;
}

.error404 .error-number {
  font-size: 170px;
  color: #e0e6ed;
  font-weight: 600;
  margin-bottom: 5px;
  margin-top: 15px;
  text-shadow: 0px 5px 4px rgba(31, 45, 61, 0.10196078431372549);
}

.error404 .error-text {
  font-size: 18px;
  color: #e0e6ed;
  font-weight: 600;
}

.error404 a.btn {
  width: 134px;
  padding: 6px;
  font-size: 17px;
  background-image: linear-gradient(229deg,
      #517281 0%,
      #3b5d70 27%,
      #4d5c82 72%,
      #5d647f 100%);
  border: none;
  letter-spacing: 2px;
  box-shadow: none;
}

audio::-webkit-media-controls-play-button,
audio::-webkit-media-controls-panel {
  background-color: #597da0;
  border-color: none !important;
  color: white;
}

audio::-webkit-media-controls-play-button {
  color: white !important;
}

audio:hover,
audio:focus,
audio:active {
  /* -webkit-box-shadow: 15px 15px 20px rgba(0, 0, 0, 0.4); */
  /* -moz-box-shadow: 15px 15px 20px rgba(0, 0, 0, 0.4); */
  /* box-shadow: 15px 15px 20px rgba(0, 0, 0, 0.4); */
  -webkit-transform: scale(1.05);
  -moz-transform: scale(1.05);
  transform: scale(1.05);
}

audio {
  -webkit-transition: all 0.5s linear;
  -moz-transition: all 0.5s linear;
  -o-transition: all 0.5s linear;
  transition: all 0.5s linear;

  -moz-border-radius: 7px 7px 7px 7px;
  -webkit-border-radius: 7px 7px 7px 7px;
  border-radius: 7px 7px 7px 7px;
}

#datePicker {
  position: absolute;
  z-index: 1;
}

.table {
  position: relative;
}

.rdrDefinedRangesWrapper {
  background: #141b2d !important;
  color: #9a9cab;
  border-right: solid 1px #9a9cab !important;
}

.rdrStaticRange {
  background: #141b2d !important;
  color: #9a9cab;
  border-bottom: 1px solid #9a9cab !important;
}

.rdrCalendarWrapper,
.rdrInputRangeInput {
  background: #141b2d !important;
}

.rdrMonthAndYearPickers select,
.rdrDayPassive .rdrDayNumber span {
  color: #9a9cab !important;
}

.rdrDayNumber span {
  color: #fff !important;
}

.rdrDateRangePickerWrapper {
  border: solid 1px #9a9cab !important;
}

.rdrStaticRange:hover .rdrStaticRangeLabel,
.rdrStaticRange:focus .rdrStaticRangeLabel {
  background: #1f1f2b !important;
}

.rdrNextPrevButton,
.rdrDateDisplayItem,
.rdrDateDisplayItem input {
  background: #fff !important;
  color: #000 !important;
}

.rdrDateDisplayItemActive {
  border-color: #fff !important;
}

.rdrDateDisplayWrapper {
  background-color: #1f1f2b !important;
}

.rdrDefinedRangesWrapper .rdrStaticRangeSelected {
  color: #c379cf !important;
}

.rdrDayEndOfMonth .rdrInRange,
.rdrDayEndOfMonth .rdrStartEdge,
.rdrDayEndOfWeek .rdrInRange,
.rdrDayEndOfWeek .rdrStartEdge,
.rdrStartEdge,
.rdrInRange,
.rdrEndEdge,
.rdrDayToday .rdrDayNumber span:after {
  background: #c379cf !important;
}

.rdrInputRangeInput {
  border: solid 1px #c379cf !important;
  color: #fff !important;
}

.rdrInputRangeInput:focus,
.rdrInputRangeInput:hover {
  border-color: #ada6f2;
  outline: 0;
  color: #fff !important;
}

.rdrDayEndOfMonth .rdrDayInPreview,
.rdrDayEndOfMonth .rdrDayStartPreview,
.rdrDayEndOfWeek .rdrDayInPreview,
.rdrDayEndOfWeek .rdrDayStartPreview {
  border-top-right-radius: 1.333em;
  border-bottom-right-radius: 1.333em;
  border-right-width: 1px;
  right: 0px;
  border-color: #ada6f2 !important;
}

.rdrDayStartOfMonth .rdrDayInPreview,
.rdrDayStartOfMonth .rdrDayEndPreview,
.rdrDayStartOfWeek .rdrDayInPreview,
.rdrDayStartOfWeek .rdrDayEndPreview {
  border-top-left-radius: 1.333em;
  border-bottom-left-radius: 1.333em;
  border-left-width: 1px;
  left: 0px;
  border-color: #ada6f2 !important;
}

.rdrDayEndPreview,
.rdrDayStartPreview {
  border-color: #ada6f2 !important;
}

.rdrDayInPreview {
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-color: #ada6f2 !important;
}

.rdrYearPicker option,
.rdrMonthPicker option {
  background: #262635 !important;
}

.rdrYearPicker select::-webkit-scrollbar {
  height: 0px;
  /* height of horizontal scrollbar ← You're missing this */
  width: 4px;
  /* width of vertical scrollbar */
  border: 1px solid #d5d5d5;
}

.rdrYearPicker select::-webkit-scrollbar-track {
  background: #0e1726;
}

.rdrYearPicker select::-webkit-scrollbar-thumb {
  background: #262635;
}

.rdrDateDisplayItemActive {
  border: 3px solid #959595 !important;
}

/* pagination css */

.pagination {
  /* float: right; */
}

.page-link {
  border-radius: 50% !important;
  padding: 5px 13px;
  background-color: #1f2940;
}

.page-item.disabled a.page-link {
  background-color: #ff71a5;
  width: 40px;
  height: 40px;
  color: #fff;
  font-size: 20px;
  border-radius: 5px !important;
}

.page-item {
  padding: 5px;
}

.page-item a.page-link {
  color: white;
  border-radius: 5px !important;
  font-size: 20px;
}

table tr td,
table tr th {
  vertical-align: middle !important;
  /* border: 1px solid; */
}

.page-item.active .page-link {
  z-index: 1;
  color: #fff !important;
  background-color: linear-gradient(to right,
      #673ab7 0,
      #884af1 100%) !important;
  border-color: linear-gradient(to right, #673ab7 0, #884af1 100%) !important;
}

.page-item.active .page-link:hover {
  background-color: linear-gradient(to right,
      #673ab7 0,
      #884af1 100%) !important;
  color: #fff !important;
}

.page-link:focus {
  box-shadow: none;
}

.page-item a.page-link:hover {
  color: linear-gradient(to right, #673ab7 0, #884af1 100%) !important;
  background-color: linear-gradient(to right,
      #673ab7 0,
      #884af1 100%) !important;
}

.user_profile_info {
  box-shadow: none;
  max-height: 820px;
  min-height: 600px;
}

.userProfilePost {
  width: 100% ;
  height: 238px;
  width: 100%;
}

.userProfilePost {

  height: 238px;
  width: 95%;
  object-fit: cover;
  display: block;
  /* border: 1px solid #eef2f6; */
  background: #fff;
  border-radius: 10px;
}

/* dashboard css */

.h5-heading {
  margin-bottom: 0;
  font-size: 15px;
  font-weight: 600;
  color: #506690;
}

.text-p {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 0;
  align-self: center;
}

.icon-primary {
  display: inline-block;
  align-self: center;
  padding: 10px;
  border-radius: 12px;
  margin-right: 16px;
  color: #eaf1ff;
  background: #4361ee;
}

.icon-warning {
  display: inline-block;
  align-self: center;
  padding: 10px;
  border-radius: 12px;
  margin-right: 16px;
  color: #eaf1ff;
  background: #e2a03f;
}

.icon-success {
  display: inline-block;
  align-self: center;
  padding: 10px;
  border-radius: 12px;
  margin-right: 16px;
  color: #eaf1ff;
  background: #009688;
}

.icon-secondary {
  display: inline-block;
  align-self: center;
  padding: 10px;
  border-radius: 12px;
  margin-right: 16px;
  color: #eaf1ff;
  background: #805dca;
}

.icon-danger {
  display: inline-block;
  align-self: center;
  padding: 10px;
  border-radius: 12px;
  margin-right: 16px;
  color: #eaf1ff;
  background: #da444e;
}

/* chart */
.pie>* {
  max-height: 350px;
}

/* history table  */
.active-history {
  box-shadow: 0px 0px 5px 5px gray;
  -webkit-transform: scale(1.05);
  -moz-transform: scale(1.05);
  transform: scale(1.05);
}

.styles-module_Editext__input__2-M50,
.styles-module_Editext__button__sxYQX {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.bg-primary-gradient {
  background-image: linear-gradient(to left,
      #0db2de 0,
      #005bea 100%) !important;
}

.bg-purple-gradient {
  background-image: linear-gradient(to right,
      #673ab7 0,
      #884af1 100%) !important;
}

.bg-dark-gradient {
  background-image: linear-gradient(to right,
      #3b3a3b 0,
      #68666d 100%) !important;
  color: white !important;
}

.bg-danger-gradient {
  /* background-image: linear-gradient(45deg, #5E35B1, #9166dc) !important;\ */
  box-shadow: rgb(231 7 101 / 35%) 0px 0px 2px 2px;
  background-color: #e70765 !important;
}

.bg-success-gradient {
  background-image: linear-gradient(to left,
      #48d6a8 0,
      #029666 100%) !important;
}

.bg-warning-gradient {
  background-image: linear-gradient(to left, #efa65f, #f76a2d) !important;
}

#compositeline canvas,
#compositeline2 canvas,
#compositeline3 canvas,
#compositeline4 canvas {
  width: 100% !important;
}

.add-button {
  background-color: "#D9386A" !important;
}

.edit-button {
  background-image: linear-gradient(to left,
      #48d6a8 0,
      #029666 100%) !important;
}

.delete-button {
  background-image: linear-gradient(45deg, #d9386a, #f7778c) !important;
}

.info-button {
  background-image: linear-gradient(to right,
      #46b7eb 0,
      #8cd8eb 100%) !important;
}

.history-button {
  background-image: linear-gradient(to left, #f3a85d, #f7772d) !important;
}

.close-button {
  background-image: linear-gradient(to right,
      #3b3a3b 0,
      #68666d 100%) !important;
}

.bg-submit-gradient {
  background-image: linear-gradient(to right,
      #d9386a 0,
      #f87da4 100%) !important;
}

.bg-login-gradient {
  background-image: linear-gradient(to right,
      #d532ee 0,
      #e58bf3 100%) !important;
}

.MuiMenu-list {
  color: #4527a0;
}

.MuiSelect-select.MuiSelect-select {
  background-color: #f2f2f2;
}

.displayCountry {
  min-height: 40px;
  display: flex;
  flex-wrap: wrap;
  height: auto;
}

.displayCountry span {
  background: radial-gradient(100% 100% at 100% 0%,
      #f5448f 0%,
      #e70765 100%) !important;
  border-radius: 5px;
  color: #fff;
  display: inline-block;
  margin: 5px;

  padding: 5px 10px;
}

.table-height {
  max-height: 675px;
}

.callButton {
  background: linear-gradient(330deg, rgb(221 3 3) 0%, rgb(255 21 21) 50%);
}


.callButton:hover,
.callButton:focus {
  box-shadow: 0px 4px 8px rgb(45 35 66 / 40%), 0px 7px 13px -3px rgb(45 35 66 / 30%), inset 0px -3px 0px rgb(255 56 56);
}

.purchaseButton:hover,
.purchaseButton:focus {
  box-shadow: 0px 4px 8px rgb(45 35 66 / 40%), 0px 7px 13px -3px rgb(45 35 66 / 30%), inset 0px -3px 0px rgba(0, 65, 182, 1);
}

.purchaseButton {
  background: linear-gradient(183deg, rgba(60, 113, 210, 1) 0%, rgba(0, 65, 182, 1) 56%);
}

table th:first-child {
  border-radius: 20px 0px 0px 0px;
  overflow-x: hidden;
}

table th:last-child {
  border-radius: 0px 20px 0px 0px;
  overflow-x: hidden;
}

.giftButton {
  background: rgb(218, 85, 255);
  background: linear-gradient(300deg, rgb(153 0 197) 0%, rgb(195 21 255) 55%);
}

.giftButton:hover,
.giftButton:focus {
  box-shadow: 0px 4px 8px rgb(45 35 66 / 40%), 0px 7px 13px -3px rgb(45 35 66 / 30%), inset 0px -3px 0px rgba(160, 0, 215, 1);
}

/* scrollbar css */

/* width */
::-webkit-scrollbar {
  height: 2px;
  width: 5px;
  background-color: transparent;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 1px;
  background-color: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ff9dc4;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  transition: all 5s ease-in-out;
  background: #ff9dc4;
}

/* toast css  */
.Toastify__toast-theme--light {
  background-color: #fff !important;
  color: #000 !important;
}

.Toastify__close-button--light {
  color: #000 !important;
}

.styles-module_Editext__input__2-M50 {
  width: 45% !important;
}

.styles-module_Editext__save_button__1Dlwo {
  color: rgb(0, 139, 0) !important;
}

.styles-module_Editext__cancel_button__26sqr {
  color: rgb(226, 1, 1) !important;
}

.uploadTextImage {
  margin-top: -16px;
  color: #fff;
}

.adminImage {
  bottom: -72px;
  left: 20px;
}

.adminImage:hover .imageTag {
  bottom: -3px;
}

.uploadImageButton {
  margin-top: 174px;
  margin-left: 50px;
}

.dash1 {
  background-image: linear-gradient(to right, #5b73e8, #44c4fa);
}

.btnSubmit:hover {
  background-color: #170e32;
}

.image {
  width: 300px;
  height: 80px;
  border: double 4px transparent;
  border-radius: 30px;
  padding: 4px;
  border: 3px solid #ea0062;
}

.dialogue_background_color {
  background-color: #fff;
}

.text-profile {
  color: var(--bs-color);
  font-weight: 600;
  font-size: 15px;
}

.dashBoardColor {
  background-color: #28273f;
  border-color: transparent;
}

.profile_box {
  width: 100%;
  height: 50px;
  text-align: center;
  padding-top: 10px;
  background-color: #1f1c30;
  border-radius: 10px;
  color: #e1cee3;
  /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
}

.iconBox {
  position: absolute;
  top: -50px;
  left: 35px;
  width: 60px;
  height: 60px;
  border-radius: 15px;
}

.bg-pink-gradient {
  background-image: linear-gradient(270deg, #ce5078, #580e31);
}

.bg-success-gradient {
  background-image: linear-gradient(270deg, #389461, #42b883) !important;
}

.bg-warning-gradient {
  background-image: linear-gradient(270deg, #da8115, #ef952e) !important;
}

.bg-info-gradient {
  background-image: linear-gradient(to bottom right, #18879d, #52b1c6);
}

.hr.horizontal.light {
  background-image: linear-gradient(90deg,
      hsla(0, 0%, 100%, 0),
      #fff,
      hsla(0, 0%, 100%, 0));
}

.hr.horizontal {
  background-color: transparent;
}

.modal-title_dialogue {
  background-color: transparent;
}

/* media quarry  */
@media only screen and (max-width: 1140px) {
  .gift_Text {
    font-size: 18px;
  }

  .gift_Text_PlatformType {
    font-size: 18px;
  }
}

.infoTable {
  color: #000 !important;
  font-weight: 600;
  margin-left: 20px;
}

/* 
.mainDash {
  background-color: #5E35B1;
  padding: 18px;
  border-radius: 12px;
  position: relative;
  overflow: hidden;
}

.mainDash1 {
  background-color: #5E35B1;
  padding: 25px;
  border-radius: 20px;
  position: relative;
  overflow: hidden;
}


.mainDash::after {
  content: "";
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background-color: #4527A0;
  position: absolute;
  top: -35px;
  right: -50px;
}

.mainDash::before {
  content: "";
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background-color: #512EA9;
  position: absolute;
  top: -60px;
  right: 10px;
}

.mainDash .dashIcon {
  color: #fff;
  padding: 5px;
  background-color: #4527a0;
  width: 44px;
  height: 44px;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;

}

.mainDash .dashCount {
  font-size: 2.125rem;
  color: #fff;
  font-weight: bold;
  padding-left: 5px;
  margin: 14px 8px 6px 0px;
}

.mainDash .dashText {
  font-size: 22px;
  color: #b39ddb;
  font-weight: bold;
} */

.mainDash {
  padding: 20px;
  background-color: #5e35b1;
  position: relative;
  border-radius: 25px;
  color: #fff;
}

.mainDash .dashIcon {
  position: absolute;
  top: -24px;
  left: 50%;
  transform: translateX(-50%);
  width: 55px;
  height: 55px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 22px;
  border: 4px solid;
}

.thisDays .thisTime {
  background-color: #ede7f6;
  padding: 4px 8px;
  border-radius: 10px;
  margin: 0px 3px;
  font-weight: bold;
  color: #673ab7;
  font-size: 12px;
  cursor: pointer;
}

.thisTime.actives {
  background-color: #e91e63;
  color: #fff;
}

.dateShow {
  color: #e91e63;
}

.daterange {
  color: #e91e63;
}

.profile_bar {
  box-shadow: 0 0 10px rgb(0 0 0 / 20%);
  background: #fff;
}

.userActive {
  width: 50px;
  height: 50px;
  background-color: transparent;
  border-radius: 5px;
  border-bottom: 5px solid #e4065d;
  padding: 3px !important;
}

.profileImage {
  width: 220px;
  height: 220px;
  object-fit: cover;
  display: block;
  border-radius: 50%;
}

.profileCard {
  height: 297px;
}

.midCss {
  display: flex;
  justify-content: center;
  align-items: stretch;
}

.contact_user {
  height: 50%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-end;
}

.styles-module_Editext__input__2-M50 {
  width: "20% !important";
}

.follower_count {
  border-radius: 10px;
  font-size: 16px;
  font-weight: 600;
  padding: 9px 0;
  background: #fff;
  background: #fefefe;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.16);
  border-radius: 11px;
  font-weight: 700;
  color: var(--bs-color);
}

.imageUser {
  width: 80px;
  height: 80px;
  display: block;
  border: double 2px transparent;
  border-radius: 45px;
  background-image: linear-gradient(white, white),
    linear-gradient(144deg, #ffffff, #ffffff);
  background-origin: border-box;
  background-clip: content-box, border-box;
}

/* media quarry  */
@media only screen and (max-width: 1270px) and (min-width: 991px) {
  .profileImage {
    width: 200px;
    height: 200px;
    display: block;
  }
}

@media only screen and (max-width: 767px) {
  .profile_follow_bar {
    margin-top: 20px;
  }
}

input.daterange {
  text-align: center;
}

.EditBar {
  background-color: #fff;
  box-shadow: 0 0 10px rgb(0 0 0 / 20%);
  margin-bottom: 10px;
  font-size: 18px !important;
  cursor: pointer;
  border-radius: 20px;
  /* height: 38vh; */
}

.text-main {
  color: #595695 !important;
}

/* media quarry  */
@media only screen and (max-width: 1200px) {
  .mainSideBio {
    display: flex;
    width: 100%;
  }

  .mainSideBio .sideBio {
    width: 50%;
  }

  .aboutMe {
    margin-top: 15px;
  }
}

@media only screen and (max-width: 1024px) {
  .UserProfileDialogue {
    width: 475px;
    height: 500px;
  }
}

@media only screen and (max-width: 768px) {
  .UserProfileDialogue {
    width: 194px;
    height: 291px;
  }
}

/* media quarry  */
@media only screen and (max-width: 756px) {
  .profile_Image img {
    display: none !important;
  }

  .profile-content {
    top: 10px;
  }

  .userCnt {
    margin-top: 10px;
  }
}

ul.pagination {
  margin: 0;
  align-items: center;
}

/* ul.pagination .page-item{
  width: 50px;
  height: 50px;
} */

ul.pagination .page-item a {
  background-color: #e70765;
}

ul.pagination .page-item.active a {
  border-color: #e70765;
}

.subReport {
  max-height: 350px;
  white-space: nowrap;
  overflow-y: auto;
}

/* input[type="email"]:invalid {
  border: 1px solid red;
} */

input[type="number"]:out-of-range {
  border: 1px solid red;
  color: red;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="email"]:focus {
  /* background: yellow !important; */
  color: #000;
  border: 1px solid #2196f3;
}

.text {
  margin: 0px;
  font-size: 0.875rem;
  font-weight: 500;
  color: rgb(18, 25, 38);
  font-family: Hellix;
  line-height: 1.75;
}

.sidebar_button {
  width: 220px;
}

.dropdown-option-text {
  color: #000 !important;
}

.dropdown-chart {
  border-radius: 12px;
  min-width: 90px !important;
  padding: 0;
}

.dropdown-toggle {
  border-color: #000;

}

.dropdown-toggle:focus {
  border-color: #2992ff;
}

.dropdown-option-text:hover {
  color: #2992ff !important;
}

@media screen and (max-width: 425px) {
  .userProfileUserName {
    font-size: 21px;
  }

  .profileImage {
    width: 170px;
    height: 170px;
  }

  .UserProfileDialogue {
    width: 220px;
    height: 290px;
  }

  .sidebar_button {
    width: 35px;
  }
}

@media screen and (max-width: 375px) {
  .sidebar-closed>.sidebar-wrapper {
    width: 79px;
  }
}

.Incoming {
  color: green;
}

.Outgoing {
  color: rgb(0, 135, 208);
}

.MissedCall {
  color: red;
}

.dash-drop {
  display: inline-block;
  outline: 0;
  border: 0;
  cursor: pointer;
  font-weight: 600;
  border: 1px solid #e5065e;
  color: #e5065e;
  border-radius: 6px;
  font-size: 14px;
  transition: box-shadow 0.15s ease, transform 0.15s ease;
  width: 100px;
  background-color: #fdeff5;
}

.dropdown-toggle:focus {
  border-color: #e5065e !important;
}

.dashDrop {
  color: #e5065e;
  background-color: #fdeff5;
  border: 1px solid #e5065e;
  overflow: hidden;
}

.dashDrop li {
  padding: 10px 5px;
  text-align: center;
  transition: 0.3s;
}

.dashDrop li:hover {
  background-color: #ffc3dd;
}

.form-group label,
label,
.form-label {
  margin-bottom: 0px !important;
}

.daterange {
  display: inline-block;
  outline: 0;
  border: 0;
  cursor: pointer;
  font-weight: 600 !important;
  border: 1px solid #e5065e;
  color: #e5065e !important;
  border-radius: 6px;
  font-size: 14px;
  transition: box-shadow 0.15s ease, transform 0.15s ease;
  width: 100px;
  background-color: #fdeff5;
}

.daterange.form-control:focus {
  box-shadow: none;
  border-color: #e5065e !important;
  color: #e5065e;
  font-weight: 600;
  background-color: #fdeff5 !important;
}

.daterange.form-control[readonly] {
  background-color: #fdeff5 !important;
}

.daterangepicker td.active,
.daterangepicker td.active:hover {
  background-color: #e5065e !important;
}

.daterangepicker .drp-selected {
  color: #e5065e;
}

.apexcharts-toolbar {
  display: none !important;
}

.table_title {
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 48px;
  text-transform: capitalize;
}

.text-underline {
  color: #000;
  text-decoration: underline !important;
  text-underline-position: under;
}

.text-underline:hover {
  color: #dd0b5e;
}

.btn-grad {
  background-image: linear-gradient(to right,
      #d31027 0%,
      #ea384d 51%,
      #d31027 100%);
  margin: 10px 0 0 10px;
  padding: 10px 20px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  box-shadow: 0 0 20px #eee;
  border-radius: 10px;
  border: none;
}

.btn-grad:hover {
  background-position: right center;
  /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}

.dialog__input__title {
  font-style: normal;
  font-weight: 600;
  font-size: 34px;
  line-height: 46px;
  color: #000;
}

.dialog__inputTitle {
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  color: #000;

}

.btn-hover {
  width: 90px;
  font-size: 19px;
  font-weight: 600;
  color: #fff;
  cursor: pointer;
  margin: 20px;
  height: 40px;
  text-align: center;
  border: none;
  background-size: 300% 100%;
  border-radius: 10px;
  moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.btn-hover:hover {
  background-position: 100% 0;
  moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.btn-hover:focus {
  outline: none;
}

.btn-hover.color-11 {
  background: linear-gradient(325deg, rgba(231, 7, 101, 1) 0%, rgb(245 40 127) 56%);
  box-shadow: 0 4px 15px 0 rgba(236, 116, 149, 0.75);
}

.gif_backGround {
  width: 160px;
  height: 160px;
  background-color: #f3f3f3;
  border-radius: 50%;
  display: block;
  padding-top: 12px;
}

.giftImage {
  width: 136px;
  height: 136px;
  object-fit: cover;
  display: block;
  background: #e3e3e3;
}

.product-search {
  background: rgba(228, 6, 93, 0.02);
}

.fas,
.fa {
  font: normal normal normal 26px/1 FontAwesome;
  font-weight: 100 !important;
}

.following {
  width: 259px;
  height: 65px;
  text-align: center;
  vertical-align: middle;
  background: rgba(188, 33, 94, 0.04);
  font-weight: 600;
  font-size: 26px;
  color: #e4065d;
  float: right;
  margin: 0 25px 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0.8px solid rgba(228, 6, 93, 0.64);
  border-radius: 9px;
}

.postCard {
  height: 306px;
}

.card .card-body {
  font-family: "Hellix";
  padding: 10px;
}

.card-body {
  color: var(--bs-card-color);
  flex: 1 1 auto;
}

.select_image {
  height: 130px;
  width: 130px;
  border: 2px dashed #808080;
  text-align: center;
  margin-top: 10px;
  position: relative;
  overflow: hidden;
  border-radius: 15px;
}

.form-check-input:checked {
  background-color: #e5065e;
  border-color: #e5065e;
}

.form-check-input {
  height: 20px;
  width: 20px;
  margin-right: 5px;
}

.form-check-label {
  margin-left: 10px
}
@media screen and (min-device-width: 320px) and (max-device-width: 768px) { 
  .hide-image-dialog {
    display: none;
  }
}
.form-check-input:focus{
  border-color: #e5065e;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(154 13 253 / 5%);
}



:root {
    --bs-color: #000;
    --bs-text-color: #e5065e;
}
.text-color{
    color:var(--bs-text-color)
}



html {
    min-height: 100%
}

body {
    color: #000;
    height: 100%;
    font-size: 0.875rem;
    background: #FFF;
    overflow-x: hidden;
    overflow-y: auto;
    letter-spacing: 0.0312rem;
    font-family: 'Hellix';
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: #e70765;
}

:focus {
    outline: none;
}

::-moz-selection {
    /* Code for Firefox */
    color: #4361ee;
    background: transparent;
}

::selection {
    color: #4361ee;
    background: transparent;
}

p {
    margin-top: 0;
    margin-bottom: 0.625rem;
    color: #000
}

hr {
    margin-top: 20px;
    margin-bottom: 20px;
    border-top: 1px solid #191e3a;
}

strong {
    font-weight: 600;
}

code {
    color: #e7515a;
}

/*Page title*/

.page-header {
    border: 0;
    margin: 0;
}

.page-header:before,
.page-header:after {
    display: table;
    content: "";
    line-height: 0
}

.page-header:after {
    clear: both
}

.page-title h3 {
    margin: 0;
    margin-bottom: 0;
    font-size: 15px;
    color: #bfc9d4;
    font-weight: 700;
}

.page-title span {
    display: block;
    font-size: 11px;
    color: #888;
    font-weight: normal
}

.main-container {
    min-height: 100vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;

    -ms-flex-wrap: wrap;
    flex-wrap: wrap;

    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    padding: 0 0 0 16px;
}

#container.fixed-header {
    margin-top: 56px;
}

#content {
    background: #fff;
    position: relative;
    width: 50%;
    flex-grow: 8;
    margin-top: 57px;
    margin-bottom: 0;
    margin-left: 216px;
    transition: .6s;
    /* padding-top: 25px; */
    padding-left: 15px;
    /* border-radius: 25px 25px 0 0; */
    /* margin-right: 20px; */
    padding-bottom: 15px;
}

.main-container-fluid>.main-content>.container {
    /* float: left; */
    width: 100%
}

#content>.wrapper {
    -webkit-transition: margin ease-in-out .6s;
    -moz-transition: margin ease-in-out .6s;
    -o-transition: margin ease-in-out .6s;
    transition: margin ease-in-out .6s;
    position: relative
}

.widget {
    padding: 0;
    margin-top: 0;
    margin-bottom: 0;
    box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, .03);
}

.layout-top-spacing {
    /* margin-top: 20px; */
}

.layout-spacing {
    padding-bottom: 40px;
}

.layout-px-spacing {
    padding: 0 20px 0 18px !important;
    min-height: calc(100vh - 170px) !important;
    /* background-color: #fff; */
    border-radius: 15px;
    /* padding-top: 20px !important; */
    padding-left: 15px !important;
    margin-right: 20px !important;
    margin-left: 5px !important;
    margin-top: 9px !important;
    /* height: 100vh; */
}

@media screen and (max-width : 991px) {
    #content {
        margin-left: 20px !important;
    }
}

/* .sidebar-closed .layout-px-spacing {
    padding: 0 16px !important;
} */

.widget.box .widget-header {
    background: #0e1726;
    padding: 0px 8px 0px;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
}

.row [class*="col-"] .widget .widget-header h4 {
    color: #ebedf2;
    font-size: 17px;
    font-weight: 600;
    margin: 0;
    padding: 16px 15px;
}

.seperator-header {
    background: transparent;
    box-shadow: none;
    margin-bottom: 40px;
    border-radius: 0;
}

.seperator-header h4 {
    margin-bottom: 0;
    line-height: 1.4;
    padding: 5px 8px;
    font-size: 15px;
    border-radius: 4px;
    letter-spacing: 1px;
    display: inline-block;
    background: rgba(0, 150, 136, 0.26);
    color: #009688;
    font-weight: 500;
}

.widget .widget-header {
    border-bottom: 0px solid #f1f2f3;
}

.widget .widget-header:before,
.widget .widget-header:after {
    display: table;
    content: "";
    line-height: 0
}

.widget .widget-header:after {
    clear: both
}

.widget-content-area {
    padding: 20px;
    position: relative;
    background-color: #ffffff;
    box-shadow: 0 0 10px rgba(0, 0, 0, .06);
    border-radius: 10px;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    border-radius: 35px;
}

.content-area {
    max-width: 58.333333%;
    margin-left: 80px;
}


/* 
=====================
    Navigation Bar
=====================
*/

.header-container {
    z-index: 1030;
    border-bottom: 1px solid #060818;
}

.header-container .navbar {
    padding: 4px 0;
}

.navbar {
    padding: 0;
    background: #060818;
}

.navbar .theme-brand {
    padding: 0.9px 0 0.9px 27px !important;
    border-radius: 6px 6px 0 0;
    justify-content: space-between;
}

.navbar .theme-brand li.theme-logo {
    align-self: center;
}

.navbar .theme-brand li.theme-logo img {
    width: 34px;
    height: 34px;
}

.navbar .theme-brand li.theme-text {
    margin-right: 47px;
}

.navbar .theme-brand li.theme-text a {
    font-size: 24px !important;
    color: #e5065e !important;
    line-height: 2.75rem;
    padding: 0 0.8rem;
    text-transform: initial;
    position: unset;
    font-weight: 700;
}

.navbar.expand-header {
    margin: 0
}

.navbar-brand {
    width: 5.5rem;
    padding-top: 0.0rem;
    padding-bottom: 0.0rem;
    margin-right: 0.0rem;
}

.navbar-expand-sm .navbar-item {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

.navbar-expand-sm .navbar-item .nav-link {
    line-height: 2.75rem;
    padding: 6.24px 9.6px;
    text-transform: initial;
    position: unset;
}

.header-container .navbar .dropdown-menu {
    border-radius: 6px;
    border-color: #e5065e;
}

.header-container .navbar .dropdown-item {
    line-height: 1.8;
    font-size: 0.96rem;
    padding: 15px 0 15px 0;
    word-wrap: normal;
}

.header-container .navbar .navbar-item .nav-item .dropdown-item.active,
.header-container .navbar .navbar-item .nav-item .dropdown-item:active {
    background-color: transparent;
    color: #16181b;
}

.header-container .navbar .navbar-item .nav-item.dropdown .nav-link:hover span {
    color: #e5065e !important;
}

.header-container .navbar .navbar-item .nav-item.dropdown .dropdown-menu {
    top: 139% !important;
    border-radius: 0;
    border: none;
    border-radius: 6px;
    -webkit-box-shadow: 0 6px 10px 0 rgba(0, 0, 0, .14), 0 1px 18px 0 rgba(0, 0, 0, .12), 0 3px 5px -1px rgba(0, 0, 0, .2);
    box-shadow: 0 6px 10px 0 rgba(0, 0, 0, .14), 0 1px 18px 0 rgba(0, 0, 0, .12), 0 3px 5px -1px rgba(0, 0, 0, .2);
    background: #1b2e4b;
    padding: 10px;
}

.header-container .navbar .navbar-item .nav-item.dropdown .dropdown-menu.show {
    top: 107% !important;
}

.header-container .navbar .navbar-item .nav-item.dropdown .dropdown-menu:after {
    position: absolute;
    content: '';
    top: -10px;
    margin-left: -7px;
    height: 0;
    width: 0;
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
    border-bottom: 15px solid #fff;
    left: auto;
    right: 7px;
    border-bottom-color: #1b2e4b;
}

/*   Language   */

.header-container .navbar .language-dropdown {
    margin-left: 20px;
}

.header-container .navbar .language-dropdown a.nav-link {
    position: relative;
    font-size: 24px;
    padding: 0;
}

.header-container .navbar .language-dropdown a.dropdown-toggle:after {
    display: none;
}

.header-container .navbar .language-dropdown a.dropdown-toggle img {
    width: 20px;
    height: 20px;
    margin: 11px 0px;
}

/*   Language Dropdown  */
.header-container .navbar .navbar-item .nav-item.dropdown.language-dropdown .dropdown-menu {
    min-width: 9rem;
    left: 50% !important;
    transform: translate(-50%, 0) !important;
    box-shadow: none;
    top: 139% !important;
    z-index: 1070;
    padding: 6px 6px;
    border-radius: 4px;
}

.header-container .navbar .navbar-item .nav-item.dropdown.language-dropdown .dropdown-menu.show {
    top: 107% !important;
}

.header-container .navbar .language-dropdown .dropdown-menu:before {
    top: -10px;
    left: 41%;
    right: auto !important;
}

.header-container .navbar .language-dropdown .dropdown-menu:after {
    top: -8.5px;
    left: 49% !important;
    right: auto !important;
}

.header-container .navbar .language-dropdown .dropdown-menu .dropdown-item:hover {
    background: transparent;
}

.header-container .navbar .language-dropdown .dropdown-menu .dropdown-item.active,
.header-container .navbar .language-dropdown .dropdown-menu .dropdown-item:active {
    background: transparent;
    color: #16181b;
}

.header-container .navbar .language-dropdown .dropdown-menu a img {
    width: 20px;
    height: 20px;
    margin-right: 16px;
}

.header-container .navbar .language-dropdown .dropdown-menu a span {
    color: #bfc9d4;
    font-weight: 500;
}

.header-container .navbar .language-dropdown .dropdown-menu a.dropdown-item:hover span {
    color: #fff;
}

/*Message Dropdown*/
.navbar .navbar-item .nav-item.dropdown.message-dropdown {
    margin-left: 20px;
}

.navbar .navbar-item .nav-item.dropdown.message-dropdown .nav-link {
    padding: 0 0;
}

.navbar .navbar-item .nav-item.dropdown.message-dropdown .nav-link:after {
    display: none;
}

.navbar .navbar-item .nav-item.dropdown.message-dropdown .nav-link svg {
    color: #888ea8;
    width: 22px;
    height: 22px;
    stroke-width: 1.6;
}

.navbar .navbar-item .nav-item.dropdown.message-dropdown .nav-link span.badge {
    position: absolute;
    top: 13px;
    right: 2px;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    padding: 1px 5px 0px;
    font-size: 10px;
    color: #fff !important;
    background: #4361ee;
    border: 1px solid #e5065e;
    top: 10px;
    right: -8px;
}

.navbar .navbar-item .nav-item.dropdown.message-dropdown.double-digit .nav-link span.badge {
    top: 11px;
    right: 1px;
    width: 22px;
    height: 22px;
    padding: 3px 3px 0px;
    font-size: 9px;
}

.navbar .navbar-item .nav-item.dropdown.message-dropdown .dropdown-menu {
    min-width: 13rem;
    right: -15px;
    left: auto;
    padding: 10px !important;
}

.navbar .navbar-item .nav-item.dropdown.message-dropdown .dropdown-menu.show {
    top: 107% !important;
}

.navbar .navbar-item .nav-item.dropdown.message-dropdown .dropdown-menu .dropdown-item {
    padding: 8px 8px;
}

.navbar .navbar-item .nav-item.dropdown.message-dropdown .dropdown-menu .dropdown-item.active,
.navbar .navbar-item .nav-item.dropdown.message-dropdown .dropdown-menu .dropdown-item:active {
    background-color: transparent;
}

.navbar .navbar-item .nav-item.dropdown.message-dropdown .dropdown-menu .dropdown-item:focus,
.navbar .navbar-item .nav-item.dropdown.message-dropdown .dropdown-menu .dropdown-item:hover {
    background-color: transparent;
}

.navbar .navbar-item .nav-item.dropdown.message-dropdown .dropdown-menu .dropdown-item:first-child {
    padding-top: 8px;
}

.navbar .navbar-item .nav-item.dropdown.message-dropdown .dropdown-menu:after {
    right: 17px;
}

.navbar .navbar-item .nav-item.dropdown.message-dropdown .dropdown-menu .dropdown-item:last-child {
    padding-bottom: 8px;
    cursor: pointer;
}

.navbar .navbar-item .nav-item.dropdown.message-dropdown .dropdown-menu .media {
    margin: 0;
}

.navbar .navbar-item .nav-item.dropdown.message-dropdown .dropdown-menu .media .avatar {
    position: relative;
    display: inline-block;
    width: 39px;
    height: 39px;
    font-size: 14px;
    margin-right: 11px;
    font-weight: 500;
}

.navbar .navbar-item .nav-item.dropdown.message-dropdown .dropdown-menu .media .avatar .avatar-title {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: #304aca;
    color: #fff;
    font-weight: 600;
}

.navbar .navbar-item .nav-item.dropdown.message-dropdown .dropdown-menu .media img {
    width: 40px;
    height: 40px;
    border-radius: 12px;
    border: 3px solid rgba(224, 230, 237, 0.16);
}

.navbar .navbar-item .nav-item.dropdown.message-dropdown .dropdown-menu .media .media-body h5.usr-name {
    font-size: 15px;
    margin-bottom: 0px;
    color: #bfc9d4;
    font-weight: 500;
}

.navbar .navbar-item .nav-item.dropdown.message-dropdown .dropdown-menu .dropdown-item:hover .media-body h5.usr-name {
    color: #fff;
}

.navbar .navbar-item .nav-item.dropdown.message-dropdown .dropdown-menu .media .media-body {
    align-self: center;
}

.navbar .navbar-item .nav-item.dropdown.message-dropdown .dropdown-menu .media .media-body p.msg-title {
    font-size: 10px;
    font-weight: 700;
    color: #888ea8;
    margin-bottom: 0;
    letter-spacing: 0;
}

/*Notification Dropdown*/

.navbar .navbar-item .nav-item.dropdown.notification-dropdown {
    margin-left: 16px;
}

.navbar .navbar-item .nav-item.dropdown.notification-dropdown .nav-link {
    padding: 0 0;
}

.navbar .navbar-item .nav-item.dropdown.notification-dropdown .nav-link:after {
    display: none;
}

.navbar .navbar-item .nav-item.dropdown.notification-dropdown .nav-link svg {
    color: #888ea8;
    width: 22px;
    height: 22px;
    stroke-width: 1.6;
}

.navbar .navbar-item .nav-item.dropdown.notification-dropdown .nav-link span.badge {
    position: absolute;
    display: block;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    padding: 0;
    font-size: 10px;
    color: #fff !important;
    background: #1abc9c;
    top: 7px;
    right: 2px;
}

.navbar .navbar-item .nav-item.dropdown.notification-dropdown .dropdown-menu {
    min-width: 15rem;
    right: -7px;
    left: auto;
}

.navbar .navbar-item .nav-item.dropdown.notification-dropdown .dropdown-menu .dropdown-item:hover svg {
    color: #25d5e4;
    fill: rgba(27, 85, 226, 0.23921568627450981);
}

.navbar .navbar-item .nav-item.dropdown.notification-dropdown .dropdown-menu .dropdown-item:focus,
.navbar .navbar-item .nav-item.dropdown.notification-dropdown .dropdown-menu .dropdown-item:hover {
    background-color: transparent;
    cursor: pointer;
}

.navbar .navbar-item .nav-item.dropdown.notification-dropdown .dropdown-menu .dropdown-item:not(:last-child) {
    border-bottom: 1px solid rgba(59, 63, 92, 0.25);
}

.navbar .navbar-item .nav-item.dropdown.notification-dropdown .dropdown-menu .dropdown-item .media {
    margin: 0;
}

.navbar .navbar-item .nav-item.dropdown.notification-dropdown .dropdown-menu .dropdown-item svg {
    width: 25px;
    height: 30px;
    color: #888ea8;
    margin-right: 12px;
    fill: rgba(0, 23, 55, 0.08);
}

.navbar .navbar-item .nav-item.dropdown.notification-dropdown .dropdown-menu .dropdown-item .notification-para {
    color: #506690;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.5;
    white-space: normal;
}

.navbar .navbar-item .nav-item.dropdown.notification-dropdown .dropdown-menu .dropdown-item .notification-para .user-name {
    color: #e5065e;
    font-weight: 700;
}

.navbar .navbar-item .nav-item.dropdown.notification-dropdown .dropdown-menu .dropdown-item .notification-meta-time {
    font-size: 13px;
    font-weight: 600;
    color: #bfc9d4;
}

.navbar .navbar-item .nav-item.dropdown.notification-dropdown .dropdown-menu {
    min-width: 15rem;
    right: -6px;
    left: auto;
}

.navbar .navbar-item .nav-item.dropdown.notification-dropdown .dropdown-menu.show {
    top: 107% !important;
}

.navbar .navbar-item .nav-item.dropdown.notification-dropdown .dropdown-menu .dropdown-item {
    padding: 0.625rem 1rem;
    cursor: pointer;
    border-radius: 0;
    background: transparent;
}

.navbar .navbar-item .nav-item.dropdown.notification-dropdown .dropdown-menu .media {
    margin: 0;
}

.navbar .navbar-item .nav-item.dropdown.notification-dropdown .dropdown-menu svg {
    width: 23px;
    height: 23px;
    font-weight: 600;
    color: #e2a03f;
    fill: rgba(226, 160, 63, 0.27058823529411763);
    margin-right: 9px;
    align-self: center;
}

.navbar .navbar-item .nav-item.dropdown.notification-dropdown .dropdown-menu .media.file-upload svg {
    color: #e7515a;
    fill: rgba(231, 81, 90, 0.23921568627450981);
}

.navbar .navbar-item .nav-item.dropdown.notification-dropdown .dropdown-menu .media.server-log svg {
    color: #009688;
    fill: rgba(0, 150, 136, 0.3686274509803922);
}

.navbar .navbar-item .nav-item.dropdown.notification-dropdown .dropdown-menu .media-body {
    display: flex;
    justify-content: space-between;
}

.navbar .navbar-item .nav-item.dropdown.notification-dropdown .dropdown-menu .data-info {
    display: inline-block;
    white-space: normal;
}

.navbar .navbar-item .nav-item.dropdown.notification-dropdown .dropdown-menu .data-info h6 {
    margin-bottom: 0;
    font-weight: 500;
    font-size: 14px;
    margin-right: 8px;
    color: #bfc9d4;
}

.navbar .navbar-item .nav-item.dropdown.notification-dropdown .dropdown-menu .dropdown-item:hover .data-info h6 {
    color: #fff;
}

.navbar .navbar-item .nav-item.dropdown.notification-dropdown .dropdown-menu .data-info p {
    margin-bottom: 0;
    font-size: 13px;
    font-weight: 600;
    color: #888ea8;
}

.navbar .navbar-item .nav-item.dropdown.notification-dropdown .dropdown-menu .icon-status {
    display: inline-block;
    white-space: normal;
}

.navbar .navbar-item .nav-item.dropdown.notification-dropdown .dropdown-menu .icon-status svg {
    margin: 0;
}

.navbar .navbar-item .nav-item.dropdown.notification-dropdown .dropdown-menu .icon-status svg.feather-x {
    color: #bfc9d4;
    width: 19px;
    height: 19px;
    cursor: pointer;
}

.navbar .navbar-item .nav-item.dropdown.notification-dropdown .dropdown-menu .icon-status svg.feather-x:hover {
    color: #e7515a;
}

.navbar .navbar-item .nav-item.dropdown.notification-dropdown .dropdown-menu .icon-status svg.feather-check {
    color: #fff;
    background: #0d9a5d;
    border-radius: 50%;
    padding: 3px;
    width: 22px;
    height: 22px;
}

.navbar .navbar-item .nav-item.search-animated {
    position: relative;
}

.navbar .navbar-item .nav-item.search-animated svg {
    font-weight: 600;
    margin: 0 9.6px;
    cursor: pointer;
    color: #888ea8;
    position: absolute;
    width: 18px;
    height: 18px;
    top: 9px;
}

.navbar .navbar-item .nav-item form.form-inline input.search-form-control {
    width: 100%;
    font-size: 14px;
    background-color: #0e1726;
    border: none;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
    color: #888ea8;
    letter-spacing: 1px;
    padding: 0px 4px 0px 35px;
    height: 36px;
    font-weight: 600;
}

.navbar .navbar-item .nav-item form.form-inline input.search-form-control:focus {
    border: 1px solid #3b3f5c;
    box-shadow: none;
}

.navbar .navbar-item .nav-item .form-inline.search .search-form-control {
    border: 1px solid rgba(81, 83, 101, 0.28);
    width: 100%;
    width: 370px;
}

.navbar .navbar-item .nav-item form.form-inline input.search-form-control::-webkit-input-placeholder {
    color: #888ea8;
    letter-spacing: 1px;
    font-size: 13px;
}

.navbar .navbar-item .nav-item form.form-inline input.search-form-control::-ms-input-placeholder {
    color: #888ea8;
    letter-spacing: 1px;
    font-size: 13px;
}

.navbar .navbar-item .nav-item form.form-inline input.search-form-control::-moz-placeholder {
    color: #888ea8;
    letter-spacing: 1px;
    font-size: 13px;
}

.navbar .navbar-item .nav-item form.form-inline input.search-form-control:focus::-webkit-input-placeholder {
    color: #3b3f5c;
}

.navbar .navbar-item .nav-item form.form-inline input.search-form-control:focus::-ms-input-placeholder {
    color: #3b3f5c;
}

.navbar .navbar-item .nav-item form.form-inline input.search-form-control:focus::-moz-placeholder {
    color: #3b3f5c;
}

.search-overlay {
    display: none;
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: transparent !important;
    z-index: 814 !important;
    opacity: 0;
    transition: 0.6s;
}

.search-overlay.show {
    display: block;
    opacity: .1;
}

/* User Profile Dropdown*/
.navbar .navbar-item .nav-item.user-profile-dropdown {
    margin: 0 20px 0 16px;
}

.navbar .navbar-item .nav-item.user-profile-dropdown .nav-link.user {
    padding: 0;
    position: relative;
}

.navbar .navbar-item .nav-item.dropdown.user-profile-dropdown .nav-link:after {
    display: none;
}

.navbar .navbar-item .nav-item.user-profile-dropdown .nav-link img {
    width: 28px;
    border-radius: 4px;
    height: 28px;
}

.navbar .navbar-item .nav-item.user-profile-dropdown .dropdown-menu {
    max-width: 13rem;
    padding: 0 11px;
}

.navbar .navbar-item .nav-item.user-profile-dropdown .dropdown-menu .dropdown-item {
    padding: 0;
    background: transparent;
}

.navbar .navbar-item .nav-item.user-profile-dropdown .dropdown-menu .dropdown-item a {
    display: block;
    color: #bfc9d4;
    font-size: 13px;
    font-weight: 500;
    padding: 9px 14px;
}

.navbar .navbar-item .nav-item.user-profile-dropdown .dropdown-menu .dropdown-item:hover a {
    color: #fff;
}

.navbar .navbar-item .nav-item.user-profile-dropdown .dropdown-menu .dropdown-item.active,
.navbar .navbar-item .nav-item.user-profile-dropdown .dropdown-menu .dropdown-item:active {
    background-color: transparent;
}

.navbar .navbar-item .nav-item.user-profile-dropdown .dropdown-menu .dropdown-item svg {
    width: 17px;
    margin-right: 7px;
    height: 17px;
}

/* 
===============
    Sidebar
===============
*/


.sub-header-container {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1029;
    display: flex;
    z-index: 1;
    background: #dd0b5e;
}

.sub-header-container .expand-header.navbar {
    margin-left: 81px;
}

.sub-header-container .navbar {
    /* -webkit-box-shadow: 0 6px 10px 0 rgba(0, 0, 0, .14), 0 1px 18px 0 rgba(0, 0, 0, .12), 0 3px 5px -1px rgba(0, 0, 0, .2);
    -moz-box-shadow: 0 6px 10px 0 rgba(0, 0, 0, .14), 0 1px 18px 0 rgba(0, 0, 0, .12), 0 3px 5px -1px rgba(0, 0, 0, .2);
    box-shadow: 0 6px 10px 0 rgba(0, 0, 0, .14), 0 1px 18px 0 rgba(0, 0, 0, .12), 0 3px 5px -1px rgba(0, 0, 0, .2); */
    background: #fff;
    border-radius: 37px 0 0 0;
    border-bottom: 1px solid #5959594d;
    padding: 9px 24px;
    justify-content: flex-start;
    /* min-height: 53px; */
    width: 100%;
    margin-left: 230px;
    transition: .6s;
}

.sub-header-container .navbar .toggle-sidebar,
.sub-header-container .navbar .sidebarCollapse {
    position: relative;
    padding: 0 25px 0 31px;
    margin-left: 0;
}

.sub-header-container .navbar .sidebarCollapse svg {
    width: 20px;
    height: 20px;
    color: #e5065e;
    vertical-align: text-top;
    stroke-width: 1.6;
}

.page-header {
    display: flex;
    padding: 0 0;
}

.sub-header-container .breadcrumb-one {
    display: inline-block;
}

.sub-header-container .breadcrumb-one .breadcrumb {
    padding: 0;
    vertical-align: sub;
    background-color: transparent;
    margin-bottom: 0;
}

.sub-header-container .breadcrumb-one .breadcrumb-item {
    align-self: center;
    display: flex;
}

.sub-header-container .breadcrumb-one .breadcrumb-item a {
    color: #408f88;
    vertical-align: sub;
}

.sub-header-container .breadcrumb-one .breadcrumb-item a svg {
    width: 18px;
    height: 18px;
    vertical-align: sub;
    fill: #e5065e;
}

.sub-header-container .breadcrumb-one .breadcrumb-item.active a {
    color: #515365;
}

.sub-header-container .breadcrumb-one .breadcrumb-item span {
    vertical-align: sub;
}

.sub-header-container .breadcrumb-one .breadcrumb-item.active {
    color: #fff;
    font-weight: 600;
}

.sub-header-container .breadcrumb-one .breadcrumb-item+.breadcrumb-item {
    padding: 0px;
}

.sub-header-container .breadcrumb-one .breadcrumb-item+.breadcrumb-item::before {
    color: #e5065e;
    padding: 0 6px 0 6px;
    vertical-align: bottom;
    display: inline-block;
    float: none;
}

@media(max-width: 575px) {
    .page-header {
        display: block;
    }
}

.sub-header-container .more-dropdown .custom-dropdown-icon {
    padding-right: 20px;
}

.sub-header-container .more-dropdown .custom-dropdown-icon a.dropdown-toggle {
    position: relative;
    padding: 9px 35px 10px 15px;
    border: 1px solid #5c3b55;
    border-radius: 8px;
    transform: none;
    font-size: 13px;
    line-height: 17px;
    background-color: #5c3b55;
    letter-spacing: normal;
    min-width: 115px;
    text-align: inherit;
    color: #e5065e;
    box-shadow: none;
    max-height: 35px;
}

.sub-header-container .more-dropdown .custom-dropdown-icon a.dropdown-toggle svg {
    position: absolute;
    right: 15px;
    top: 11px;
    color: #e5065e;
    width: 13px;
    height: 13px;
    margin: 0;
    -webkit-transition: -webkit-transform .2s ease-in-out;
    transition: -webkit-transform .2s ease-in-out;
    transition: transform .2s ease-in-out;
    transition: transform .2s ease-in-out, -webkit-transform .2s ease-in-out;
}

.sub-header-container .more-dropdown .custom-dropdown-icon.show a.dropdown-toggle svg {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
}

.sub-header-container .more-dropdown .custom-dropdown-icon .dropdown-menu {
    position: absolute;
    right: 16px;
    top: 40px !important;
    padding: 8px 0;
    border: none;
    min-width: 155px;
    background-color: #5c3b55;
    -webkit-box-shadow: 0 6px 10px 0 rgba(0, 0, 0, .14), 0 1px 18px 0 rgba(0, 0, 0, .12), 0 3px 5px -1px rgba(0, 0, 0, .2);
    box-shadow: 0 6px 10px 0 rgba(0, 0, 0, .14), 0 1px 18px 0 rgba(0, 0, 0, .12), 0 3px 5px -1px rgba(0, 0, 0, .2);
}

.sub-header-container .more-dropdown .custom-dropdown-icon .dropdown-menu a {
    padding: 8px 15px;
    font-size: 13px;
    font-weight: 500;
    color: #e5065e;
}

.sub-header-container .more-dropdown .custom-dropdown-icon .dropdown-menu a:hover {
    background-color: #e5065e;
    color: #e5065e;
}

/* 
===============
    Sidebar
===============
*/

.logoBar {
    padding: 25px 8px;
    transition: .6s;
}

.sidebar-closed .logoBar {
    padding: 25px 0px;
}

@media (max-width: 991px) {
    .sidebar-closed .logoBar {
        padding: 0
    }
}

ul.menu i {
    font-size: 16px;
}

.sidebar-wrapper {


    width: 230px;
    position: fixed;
    z-index: 1028;
    /* border-radius: 6px 6px 0 0; */
    transition: .6s;
    height: 100vh;
    touch-action: none;
    user-select: none;
    -webkit-user-drag: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    top: 0px;
    left: 0px;
    padding: 0px 0px 16px 0px;
    background-color: #dd0b5e !important;
    color: #fff;
}

.sidebar-theme {
    background: #fff;
}


.sidebar-closed>.sidebar-wrapper {
    width: 81px;
    padding: 0 14px;
}

.sidebar-closed ul.submenu>li a {
    margin-left: 6px !important;
}



.sidebar-closed #sidebar ul.menu-categories ul.submenu>li a:hover {
    color: #FFF;
    transform: translateX(0px);
}

.sidebar-closed>#content {
    margin-left: 70px;
}

#sidebar .navbar-brand .img-fluid {
    display: inline;
    width: 44px;
    height: auto;
    margin-left: 20px;
    margin-top: 5px;
}

#sidebar .border-underline {
    border-left: 1px solid #ccc;
    height: 20px;
    margin-top: 18px;
    margin-left: 0px;
    margin-right: 8px;
}

#sidebar * {
    overflow: hidden;
    white-space: nowrap;
}

.shadow-bottom {
    display: block;
    position: absolute;
    z-index: 2;
    height: 33px;
    width: 101.6%;
    pointer-events: none;
    margin-top: -13px;
    left: -4px;
    -webkit-filter: blur(5px);
    filter: blur(3px);
    background: -webkit-linear-gradient(top, #1A233AF0 44%, #1A233ABF 73%, #2C303C00);
    background: linear-gradient(180deg, #060818 49%, #060818eb 85%, #2C303C00);
}

#sidebar ul.menu-categories {
    position: relative;
    margin: auto;
    width: 100%;
    height: calc(100vh - 127px) !important;
    overflow: auto !important;
}

#sidebar ul.menu-categories.ps {
    height: calc(100vh - 107px) !important;
    margin-right: 0;

    /* border-right: 2px solid #28273f; */
}

#sidebar .ps__thumb-y {
    right: 4px;
    background-color: #515365;
}

#sidebar .ps__rail-y:hover>.ps__thumb-y {
    background-color: #3b3f5c;
}

#sidebar ul.menu-categories li>.dropdown-toggle[data-active="true"] svg.feather-chevron-right {
    transform: rotate(90deg);
}

#sidebar ul.menu-categories li>.dropdown-toggle[aria-expanded="true"] svg.feather-chevron-right {
    transform: rotate(90deg);
}

#sidebar ul.menu-categories li.menu:first-child ul.submenu>li a {
    justify-content: flex-start;
}

#sidebar ul.menu-categories li.menu:first-child ul.submenu>li a i {
    align-self: center;
    margin-right: 12px;
    font-size: 19px;
    width: 21px;
}



#sidebar ul.menu-categories li.menu>.dropdown-toggle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    font-size: 17px;
    color: #FFF;
    padding: 10px 16px;
   
    transition: .3s;
    margin: 3px 0 !important;
    font-weight: 600;
    letter-spacing: 1px;
    margin: 0 3px;

}

#sidebar ul.menu-categories li.menu>.dropdown-toggle>div {
    align-self: center;
}

#sidebar ul.menu-categories li.menu .dropdown-toggle:after {
    display: none;
}

#sidebar ul.menu-categories li.menu>.dropdown-toggle i,
#sidebar ul.menu-categories li.menu>.dropdown-toggle svg {
    color: #fff;
    margin-right: 12px;
    vertical-align: middle;
    width: 20px;
    height: 20px;
    font-size: 20px;
    fill: rgba(0, 23, 55, 0.08);
    stroke-width: 1.6;
}

#sidebar ul.menu-categories li.menu>.dropdown-toggle[data-active="true"] {
    background: #563944;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .1), 0 1px 2px 0 rgba(0, 0, 0, .06);
    border-radius: 6px;
    color: #fafafa;
}

#sidebar ul.menu-categories li.menu>.dropdown-toggle[data-active="true"] svg,
#sidebar ul.menu-categories li.menu>.dropdown-toggle[data-active="true"] i {
    color: #FFF;
    fill: none;
}

#sidebar ul.menu-categories li.menu>.dropdown-toggle[data-active="true"]:hover svg,
#sidebar ul.menu-categories li.menu>.dropdown-toggle:hover i {
    color: #FFF !important;
}

#sidebar ul.menu-categories li.menu>.dropdown-toggle[data-active="true"] span {
    color: #FFF;
}

#sidebar ul.submenu li {
    transition: .6s;
    margin: 3px 0px;
}

#sidebar ul.submenu {
    padding: 0px 5px;
}

#sidebar ul.submenu li:hover {
    background: #FFFFFF50;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .1), 0 1px 2px 0 rgba(0, 0, 0, .06);

    color: #FFF !important;
}

#sidebar ul.menu-categories li.menu>.dropdown-toggle:hover,
#sidebar ul.menu-categories li.menu>.dropdown-toggle.dropdown-toggle:not([data-active="true"]):not([aria-expanded="true"]):hover,
#sidebar ul.menu-categories li.menu>.dropdown-toggle.active,
#sidebar ul.menu-categories li.menu>.dropdown-toggle.dropdown-toggle:not([data-active="true"]):not([aria-expanded="true"]).active {
    background: #FFFFFF50 !important;
    color: #fff !important;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .1), 0 1px 2px 0 rgba(0, 0, 0, .06) !important;
  
}

#sidebar ul.menu-categories li.menu>.dropdown-toggle[aria-expanded="true"]:not([data-active="true"]),
#sidebar ul.submenu li>.dropdown-toggle[aria-expanded="true"]:not([data-active="true"]) {
    background: #FFFFFF50 !important;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .1), 0 1px 2px 0 rgba(0, 0, 0, .06);

    color: #FFF !important;
    transition: .6s;
}

#sidebar ul.submenu li:has(.makeStyles-navLink-1.active) {
    background: #FFFFFF50;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .1), 0 1px 2px 0 rgba(0, 0, 0, .06);
  
    color: #FFF !important;
}

.makeStyles-navLink-1.active {
    background: transparent !important;
    box-shadow: unset !important;
    border-radius: unset !important;
}

#sidebar ul.menu-categories li.menu>.dropdown-toggle:hover svg,
#sidebar ul.menu-categories li.menu>.dropdown-toggle[aria-expanded="true"]:not([data-active="true"]) svg #sidebar ul.menu-categories li.menu>.dropdown-toggle:hover i,
#sidebar ul.menu-categories li.menu>.dropdown-toggle[aria-expanded="true"]:not([data-active="true"]) i {
    color: #FFF !important;
}

#sidebar ul.menu-categories li.menu>.dropdown-toggle[aria-expanded="true"]:not([data-active="true"]) svg,
#sidebar ul.menu-categories li.menu>.dropdown-toggle[aria-expanded="true"]:not([data-active="true"]) i {
    color: #FFF;
}

#sidebar ul.menu-categories li.menu>.dropdown-toggle svg.feather-chevron-right,
#sidebar ul.menu-categories li.menu>.dropdown-toggle svg.feather-chevron-right {
    vertical-align: middle;
    margin-right: 0;
    width: 15px;
}

#sidebar ul.menu-categories li.menu>.dropdown-toggle[data-active="true"] svg.flaticon-right-arrow,
#sidebar ul.menu-categories li.menu>.dropdown-toggle[data-active="true"] svg.flaticon-down-arrow {
    background-color: transparent;
}

#sidebar ul.menu-categories li.menu>a span:not(.badge) {
    vertical-align: middle;
}

#sidebar ul.menu-categories ul.submenu>li a {
    position: relative;
    display: flex;
    justify-content: space-between;
    padding: 10px 12px 10px 48px;
    padding-left: 24px;
    margin-left: 10px;
    font-size: 13px;
    color: #FFF;
}

#sidebar ul.menu-categories li.menu ul.submenu>li a:before {
    content: '';
    background-color: #FFF;
    position: absolute;
    height: 7px;
    width: 7px;
    top: 18.5px;
    margin-right: 17px !important;
    left: 13px;
    border-radius: 50%;
}

#sidebar ul.menu-categories ul.submenu>li.active a {
    color: #d997cb;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    font-weight: 600;
}

#sidebar ul.menu-categories ul.submenu>li a.dropdown-toggle {
    padding: 10px 15px 10px 37px;
    font-weight: 600;
    margin: 3px 0px;
    transition: .6s;
    font-size: 16px;
}

#sidebar ul.menu-categories li.menu ul.submenu>li.active a:before {
    background-color: #61b6cd;
}

#sidebar ul.menu-categories ul.submenu>li.active {
    position: relative;
}

#sidebar ul.menu-categories ul.submenu>li a:hover {
    color: #FFF;
    /* transform: translateX(10px); */
}

#sidebar ul.menu-categories ul.submenu>li a:hover:before {
    background-color: #FFF !important;
}

#sidebar ul.menu-categories ul.submenu>li a i {
    align-self: center;
    font-size: 9px;
}

#sidebar ul.menu-categories ul.submenu li>[data-active="true"] i {
    color: #61b6cd;
}

#sidebar ul.menu-categories ul.submenu li>[data-active="true"]:before {
    background-color: #61b6cd !important;
}

#sidebar ul.menu-categories ul.submenu li>a[data-active="true"] {
    color: #61b6cd;
}

#sidebar ul.menu-categories ul.submenu>li ul.sub-submenu>li a {
    position: relative;
    padding: 10px 12px 10px 48px;
    padding-left: 13px;
    margin-left: 47px;
    font-size: 12px;
    color: #506690;
}

#sidebar ul.menu-categories ul.submenu>li ul.sub-submenu>li.active a {
    color: #009688;
}

#sidebar ul.menu-categories ul.submenu>li ul.sub-submenu>li a:hover {
    color: #009688;
}

#sidebar ul.menu-categories ul.submenu>li ul.sub-submenu>li a:before {
    display: none;
}

#sidebar ul.menu-categories ul.submenu>li ul.sub-submenu>li.active a:before {
    background-color: #009688;
}

.overlay {
    display: none;
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, .4);
    z-index: 1035 !important;
    opacity: 0;
    transition: .6s;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    touch-action: pan-y;
    user-select: none;
    -webkit-user-drag: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.e-animated {
    -webkit-animation-duration: 0.6s;
    animation-duration: 0.6s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both
}

@-webkit-keyframes e-fadeInUp {
    0% {
        opacity: 0;
        margin-top: 10px;
    }

    100% {
        opacity: 1;
        margin-top: 0;
    }
}

@keyframes e-fadeInUp {
    0% {
        opacity: 0;
        margin-top: 10px;
    }

    100% {
        opacity: 1;
        margin-top: 0;
    }
}

.e-fadeInUp {
    -webkit-animation-name: e-fadeInUp;
    animation-name: e-fadeInUp
}

#sidebar ul.menu-categories .ps__rail-y {
    right: -4px !important;
}


/*  
    ======================
        Footer-wrapper
    ======================
*/

.footer-wrapper {
    padding: 10px 20px 10px 18px;
    display: inline-block;
    background: transparent;
    font-weight: 600;
    font-size: 12px;
    width: 100%;
    border-top-left-radius: 6px;
    display: flex;
    justify-content: space-between;
}

.main-container.sidebar-closed .footer-wrapper {
    border-radius: 0;
}

.footer-wrapper .footer-section p {
    margin-bottom: 0;
    color: #888ea8;
    font-size: 13px;
    letter-spacing: 1px;
}

.footer-wrapper .footer-section p a {
    color: #888ea8;
}

.footer-wrapper .footer-section svg {
    color: #e7515a;
    fill: rgba(231, 81, 90, 0.4196078431372549);
    width: 15px;
    height: 15px;
    vertical-align: text-top;
}




.MuiFilledInput-root {
    background-color: transparent !important;
    border: 1px solid #5e35b1;
    border-radius: 15px !important;
    /* margin: 15px 0 */
}

.MuiFormControl-root {
    margin-top: 20px !important;
}

.profile_Card {
    border-radius: 12px;
    width: 325px;
}

.profile_toggle_line {
    font-weight: 500
}

.adminProfile_image {
    width: 185px;
    height: 185px;
    object-fit: cover;
    display: block;
    border-radius: 50%;
}

.camera_icon {
    position: absolute;
    right: 10px;
    bottom: 6px;

}

.adminProfile_title {
    background-color: rgba(188, 33, 94, 0.06);
    font-size: 26px;
    border-radius: 20px 0 0 20px;
}

.profile {
    color: var(--bs-color);
    font-weight: 500;
    font-size: 24px;
    font-family: "Hellix";

}

.password {
    font-size: 25px;
    color: var(--bs-color);
    font-weight: 500;

}

.activeLine {
    position: relative;
    /* background: #e70765; */
    border-radius: 10px;
}

.activeLine::before {
    content: " ";
    background: #e70765;
    border-radius: 24px;
    height: 4px;
    width: 122px;
    position: absolute;
    bottom: -4px;
    left: 0;
}

.activeLine1 {
    position: relative;
    /* background: #e70765; */
    border-radius: 10px;
}

.activeLine1::before {
    content: " ";
    background: #e70765;
    border-radius: 24px;
    height: 4px;
    width: 100%;
    position: absolute;
    bottom: -4px;
    left: 0;
}

.card-height {
    height: 850px;
}

/*  
    ======================
        MEDIA QUERIES
    ======================
*/

@media only screen and (max-width: 1440px) {
    .activeLine::before {
        width: 98px;
    }

    .sidebar-closed>.sidebar-wrapper {
        width: 81px;
    }

    .profile {
        font-size: 19px;
    }

    .password {
        font-size: 19px;
        width: 202px;
    }

    .profile_Card {
        width: 275px
    }

    .camera_icon {
        right: 4px;
    }

    .adminProfile_image {
        width: 155px;
        height: 155px
    }

    .font1024 {
        font-size: 30px;
    }
}

@media only screen and (max-width: 1024px) {
    .adminProfile_image {
        width: 113px;
        height: 113px;
    }

    .font1024 {
        font-size: 27px;
    }

    .camera_icon {
        right: 7px
    }
}


@media screen and (min-width: 992px) {
    .sidebar-closed #sidebar .theme-brand li.theme-text a {
        display: none;
    }
}

@media only screen and (max-width: 991px) {
    .navbar .theme-brand {
        padding: 0.9px 0 0.9px 14px !important;
    }


    .sub-header-container .navbar .toggle-sidebar,
    .sub-header-container .navbar .sidebarCollapse {
        position: relative;
        padding: 0 25px 0 16px;
    }

    .sidebar-closed>.sidebar-wrapper {
        width: 230px;
        /* transition: 0.6s; */
    }

    .sub-header-container .navbar {
        margin-left: 0px;
        border-radius: 0;
    }

    .sub-header-container .expand-header.navbar {
        margin-left: 230px;
        border-radius: 37px 0 0 0;
    }



    /*
        =============
            NavBar
        =============
    */

    .main-container.sidebar-closed #content {
        margin-left: 0;
    }

    /*
        =============
            Sidebar
        =============
    */

    #content {
        margin-left: 0;
    }

    #sidebar .theme-brand {
        border-radius: 0;
    }

    #sidebar .theme-brand {
        background-color: #4361ee;
        padding: 7px 12px 7px 24px;
        border-color: #4361ee
    }

    .sidebar-closed #sidebar .theme-brand {
        padding: 0.9px 12px 0.9px 24px;
    }

    .main-container:not(.sbar-open) .sidebar-wrapper {
        width: 0;
        /* left: -5px; */
    }

    body.alt-menu .sidebar-closed>.sidebar-wrapper {
        width: 0;
        /* left: -52px; */
    }

    .main-container {
        padding: 0;

    }

    #sidebar ul.menu-categories.ps {
        height: calc(100vh - 70px) !important;
    }

    /* .sidebar-wrapper {
        position: fixed;
        top: 0px;
        left: 0;
        bottom: 0;
        width: 255px;
        z-index: 9999;
        backface-visibility: hidden;
        -webkit-backface-visibility: hidden;
        -webkit-transform: translate3d(0, 0, 0);
        border-radius: 0;
    } */

    .sidebar-closed>.sidebar-wrapper {
        padding-left: 2px;
    }

    .sidebar-noneoverflow {
        overflow: hidden;
    }

    #sidebar {
        height: 100vh !important;
        padding: 10px 0 10px 10px;
        backface-visibility: hidden;
        -webkit-backface-visibility: hidden;
        -webkit-transform: translate3d(0, 0, 0);
        /* background: #fff; */
        /* box-shadow: 0 10px 10px rgba(0, 0, 0, .1); */
    }

    .footer-wrapper {
        padding: 6px 16px 9px 16px;
    }



    .overlay.show {
        display: block;
        opacity: .7;
    }
}

@media screen and (max-width:768px) {

    .card-height {
        height: 1126px;
    }

    .camera_icon {
        right: 4px
    }

    .adminProfile_title {
        border-radius: 20px 20px 0 0;
    }

    .profile {
        color: var(--bs-color);
        font-weight: 600;
        width: 163px;
    }

    .password {
        color: var(--bs-color);
        font-weight: 600;
        width: 255px;

    }

    .sidebar-closed>.sidebar-wrapper {
        width: 230px;
    }

    .navbar .theme-brand li.theme-text {
        display: none;
        margin-right: 0;
    }

    .navbar .navbar-item .nav-item .form-inline.search .search-form-control {
        opacity: 0;
        transition: opacity 200ms, top 200ms
    }

    .navbar .navbar-item .nav-item .form-inline.search.input-focused .search-form-control {
        opacity: 1;
        transition: opacity 200ms, top 200ms
    }

    .navbar .navbar-item .nav-item .form-inline.search {
        opacity: 0;
        transition: opacity 200ms, top 200ms;
        top: -25px;
    }

    .navbar .navbar-item .nav-item .form-inline.search.input-focused {
        position: absolute;
        bottom: 0;
        top: 0;
        background: #060818;
        height: 100%;
        width: 100%;
        left: 0;
        right: 0;
        z-index: 32;
        margin-top: 0px !important;
        display: flex;
        opacity: 1;
        transition: opacity 200ms, top 200ms;
    }

    .navbar .navbar-item .nav-item.search-animated {
        position: initial;
    }

    .navbar .navbar-item .nav-item.search-animated svg {
        font-weight: 600;
        margin: 0 9.6px;
        cursor: pointer;
        color: #e5065e;
        position: initial;
        width: 24px;
        height: 24px;
        transition: top 200ms;
        top: -25px;
    }

    .navbar .navbar-item .nav-item.search-animated.show-search svg {
        margin: 0;
        position: absolute;
        top: 16px;
        left: 16px;
        width: 22px;
        height: 22px;
        color: #e5065e;
        z-index: 40;
        transition: top 200ms;
    }

    .navbar .navbar-item .nav-item .form-inline.search.input-focused .search-bar {
        width: 100%;
    }

    .navbar .navbar-item .nav-item .form-inline.search.input-focused .search-form-control {
        background: transparent;
        display: block;
        padding-left: 50px;
        padding-right: 12px;
        border: none;
    }

    .navbar .navbar-item .nav-item .form-inline.search .search-form-control {
        border: none;
        width: 100%;
        display: none;
    }

    .header-container .navbar .language-dropdown {
        margin-left: 16px;
    }

}

@media screen and (max-width: 575px) {

    .card-height {
        height: 1000px;
    }

    .navbar .navbar-item .nav-item.message-dropdown .dropdown-menu {
        min-width: 16.2rem;
    }

    .sub-header-container .breadcrumb-one .breadcrumb-item:not(.active) {
        display: none;
    }

    .sub-header-container .breadcrumb-one .breadcrumb-item+.breadcrumb-item::before {
        display: none;
    }

    .sub-header-container .breadcrumb-one .breadcrumb-item span {
        vertical-align: sub;
        font-size: 15px;
        font-weight: 700;
    }

    .sub-header-container .navbar .toggle-sidebar,
    .sub-header-container .navbar .sidebarCollapse {
        padding: 0 13px 0 16px;
    }

    .navbar .navbar-item .nav-item.dropdown .dropdown-menu:after {
        right: 45px;
    }

    .navbar .navbar-item .nav-item.dropdown.notification-dropdown .dropdown-menu {
        right: -44px;
    }

    .navbar .navbar-item .nav-item.dropdown.notification-dropdown .dropdown-menu:after {
        right: 46px;
    }

    .navbar .navbar-item .nav-item.dropdown.message-dropdown .dropdown-menu {
        right: -82px;
    }

    .navbar .navbar-item .nav-item.dropdown.message-dropdown .dropdown-menu:after {
        right: 83px;
    }

    .footer-wrapper .footer-section.f-section-2 {
        display: none;
    }
}

@media screen and (max-width: 476px) {
    .card-height {
        height: 958px;
    }

    .sidebar-closed>.sidebar-wrapper {
        width: 79px;
    }

    .profile {
        color: var(--bs-color);
        font-weight: 600;
        width: 163px;
    }

    .password {
        color: var(--bs-color);
        font-weight: 600;
        width: 203px;
    }

    .adminProfile_title {
        background-color: rgba(188, 33, 94, 0.06);
        font-size: 19px;
        border-radius: 20px 20px 0 0;
    }

    .adminProfile_image {
        width: 145px;
        height: 145px;

    }

    .profile-content {
        width: 100%;
    }

    .profile_Card {
        width: 275px
    }

    .sidebar-wrapper {
        width: 97px;
    }

    .sub-header-container .expand-header.navbar {
        margin-left: 79px;
    }

    .camera_icon {
        right: 4px;
    }
}


/* Sidebar.css */

.navLink.active .fa-solid {
  color: #FFF !important;
}

.navLink.active span {
  color: #FFF !important;
}

.navLink.active svg {
  color: #FFF !important;
}
